.article-card {
    background-color: #26272D;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    width: 100%;
    height: 100%;
    display: flex;
    text-decoration: none;
    flex-direction: column;
    box-sizing: border-box;
    transition: background-color 0.3s ease;
    transform: scale(1);
    cursor: pointer;
}

.article-card:hover {
    opacity: 0.6;
}

.thumbnail-img {
    width: 100%;
    height: 16.6vh;
    object-fit: cover;
    margin-bottom: 1rem;
    border-radius: 4px;
}

.article-text-meta-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.article-title-text {
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #E1E5EF;
}

.article-introduction-title {
    margin-top: 1.375rem;
    font-size: 0.875rem;
    color: #9FA3AD;

    line-height: 1.35;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.article-meta-box {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.article-published-date {
    color: #868686;
    font-size: 0.75rem;
}

.article-meta-line {
    margin: 0.5rem 0 0.75rem 0;
}

.article-author-view-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.article-author-box {
    display: flex;
}

.article-view-count {
    color: #A9A9A9;
    font-size: 0.75rem;
}

.article-author-logo {
    width: 1.25vw;
    height: 2.22vh;
    border-radius: 50%;
    object-fit: cover;
}

.article-author {
    display: inline-block;
    align-content: center;
    margin-left: 0.42vw;
    font-weight: 600;
    font-size: 0.75rem;
    color: #C3C3C3;
}

