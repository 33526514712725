.recommend-box {
    height: 26.6vh;
    width: 84.5vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.recommend-header-text {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1.85vh;
}

.recommend-article-card-list {
    display: grid;
    grid-template-columns: repeat(4, 20.21vw);
    gap: 1rem;
    justify-content: center;
}

.recommend-article-card {
    width: 20.21vw;
    height: 22.23vh;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    border-radius: 20px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    text-decoration: none;
}

.recommend-article-card::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: var(--bg-image);
    background-size: cover;
    background-position: center;
    opacity: 0.45;
    z-index: 1;
    transition: transform 0.3s ease;
    transform: scale(1);
    transform-origin: center;
}

.recommend-article-card:hover::before {
    transform: scale(1.1);
    opacity: 0.3;
}

.recommend-article-title-box {
    width: 100%;
    margin-bottom: 2vh;
    margin-left: 0.7vw;
    z-index: 2; /* 배경보다 위에 표시 */
}

.company-badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.875rem;
    margin-bottom: 0.5vh;
    border-radius: 15px;
}

.company-badge > span {
    font-size: 0.625rem;
    font-weight: bold;
}

.card-title {
    display: inline-block;
    width: 90%;
    font-size: 1rem;
    font-weight: 600;
    color: #FFFFFF;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
}
