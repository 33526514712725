* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Open Sans-ExtraBold", Helvetica, sans-serif;
    font-size: 16px;
}

body {
    font-family: "Open Sans", Helvetica, sans-serif;
    background-color: #141517;
    color: #d4d4d4;
}

@media (max-width: 1600px) {
    * {
        font-size: 14px;
    }
}

@media (max-width: 1280px) {
    * {
        font-size: 12px;
    }
}
