.search-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 11.8vh;
    overflow-y: auto;
}

.search-result-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 67vw;
}

.search-result-count-text {
    margin-bottom: 5.6vh;
}

.search-result-count-text > span {
    font-size: 1.25rem;
}

.search-count-text {
    color: #CACED8;
    font-weight: bold;
}

.search-result-count-text > span:nth-child(2) {
    color: #B5A9A9;
}

.search-result-tech-article-box {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 1.25vw)); /* 한 줄에 2개의 열 */
    gap: 2.5vw 7.4vh; /* column-gap, row-gap */
    width: 100%;
    box-sizing: border-box;
}

.search-article-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    text-decoration: none;
}

.article-card:hover {
    transform: translateY(-5px);
}

.article-card-logo-box {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    box-sizing: border-box;
}

.article-card-logo-img-box {
    width: 3rem;
    height: 3rem;
    border: 2px solid #141517;
    border-radius: 50%;
    overflow: hidden;
}

.article-card-logo-img-box > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.article-card-logo-company-name {
    align-content: center;
    margin-left: 0.5rem;
    color: #D2D7E4;
}

.article-card-thumbnail-box {
    width: 100%;
    height: 36.4vh;
}

.article-card-thumbnail-box > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.article-card-article-content-box {
    margin-top: 2.4vh;
}

.article-card-article-title {
    font-size: 1.75rem;
    font-weight: bold;
    color: #E4E8F2;

    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.article-card-article-summary {
    margin-top: 0.75vh;
    font-size: 1rem;
    line-height: 1.35;
    color: #AFB3BD;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.article-card-category-box {
    display: flex;
    flex-direction: row;
    margin-top: 2vh;
    gap: 0.375rem;
}

.article-card-category-button {
    display: flex;
    padding: 0.5vh 0.83vw;
    justify-content: center;
    align-items: center;

    background-color: #34363C;
    color: #FFC52E;
    border-radius: 9999px;
    cursor: pointer;
    box-sizing: border-box;
}

.article-card-category-button-text {
    font-size: 0.875rem;
}

.article-card-article-meta-box {
    display: flex;
    flex-direction: row;
    margin-top: 1.85vh;
    gap: 4px;
    color: #838589;
    font-size: 0.875rem;
}

.loading, .error, .end-message {
    color: #555;
}
