.ranking-box {
    width: 23.8vw;
    height: 100%;
    background-color: #232429;
    border-radius: 16px;
    padding: 3.7vh 1.6vw 0 1.6vw;
}

.ranking-box-title {
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 2.2vh;
}

.top-rank-article {
    width: 20.5vw;
    height: 13.5vh;
    padding: 1.85vh 0;
    border-bottom: 2px solid #2F3138;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.top-rank-article:hover {
    background-color: #272B33;
    border-radius: 8px;
}

.top-rank-article-box {
    display: flex;
    flex-direction: row;
    width: 20.5vw;
    height: 9.3vh;
    text-decoration: none;
}

.top-rank-article-number {
    color: #FFC52E;
    font-weight: 700;
    font-size: 1.5rem;
    align-content: center;
    margin: 0 0.4vw 0 0.2vw;
}

.top-rank-article-img-box {
    position: relative;
    width: 5.2vw;
    height: 9.3vh;
    overflow: visible;
}

.top-rank-article-img {
    width: 100%;
    height: 100%;
    min-width: 50px;
    min-height: 50px;
    border-radius: 11px;
    border: 1px solid #313234;
    object-fit: cover;
    z-index: 1;
}

.top-rank-article-text-box {
    margin-left: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 14.3vw;
    height: 100%;
}

.top-rank-article-text-box > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.top-rank-article-title {
    font-size: 1.125rem;
    font-weight: 500;
    color: #E1E5EF;

    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.top-rank-article-meta {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.ranking-article-title-text {
    font-size: 1rem;
    color: #E1E5EF;
    font-weight: 500;

    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.rank-article-company-name {
    color: #C3C3C3;
    font-size: 0.875rem;
    font-weight: 400;
}

.rank-article-view-count {
    color: #929394;
    font-size: 0.875rem;
    font-weight: 500;
}

.ranking-article-line {
    background: #3B3B3B;
    height: 2px;
    border: 0;
    margin-bottom: 1.85vh;
}

.rank-articles {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.rank-article-box {
    display: flex;
    flex-direction: row;
    padding: 1.85vh 0.8vw;
    border-bottom: 2px solid #2F3138;
    cursor: pointer;
    text-decoration: none;
}

.rank-article-box:hover {
    background-color: #272B33;
    border-radius: 8px;
}

.rank-article-rank-number {
    color: #FFC52E;
    font-size: 1.25rem;
    align-content: center;
    margin-right: 0.75rem;
}

.rank-article-rank-number-gray {
    color: #C9C9C9;
}

.rank-article-thumbnail-img-box {
    min-width: 4.2vw;
    min-height: 7.4vh;
    width: 4.2vw;
    height: 7.4vh;
    margin-right: 1rem;
}

.rank-article-thumbnail-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid #3B3B3B;
    border-radius: 9px;
}

.rank-article-meta-box {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.rank-article-meta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.8vh;
}