.company-detail-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 6.5vh;
    padding-left: 1.75vw;
    padding-right: 3.1vw;
    padding-bottom: 17.7vh;
    overflow-y: auto;
}

.company-detail-header-box {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 26vh;
    padding-bottom: 3.7vh;
    border-bottom: 2px solid #2F3138;
}

.company-detail-img-box {
    width: 22.4vw;
    height: 23.25vh;
}

.company-detail-company-logo-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
}

.company-detail-meta-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2.3vw;
}

.company-detail-company-name {
    font-size: 3rem;
    font-weight: bold;
    color: #E0E3EB;
}

.company-detail-article-count {
    font-size: 1.5rem;
    margin-top: 2.6vh;
    color: #E0E3EB;
}

.company-detail-order-button-box {
    display: flex;
    flex-direction: row;
    margin-top: 3.7vh;
    gap: 4px;
}

.company-detail-order-button-box > a {
    display: flex;
    width: 3.75vw;
    height: 3.05vh;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    background-color: #393E45;
    color: #E0E3EB;
    font-weight: 500;
    border-radius: 9999px;
    cursor: pointer;
    box-sizing: border-box;
}

.company-detail-order-button-box > a.active {
    background-color: #FFC52E;
    color: #1D1E21;
}

.company-detail-order-button-box > a:hover {
    background-color: #FFC52E;
    color: #1D1E21;
}

.company-detail-order-button-box > div > span {
    font-size: 0.875rem;
}

.company-detail-article-list-box {
    margin-top: 2.22vh;
    display: grid;
    grid-template-columns: repeat(4, 19.8vw);
    gap: 4.4vh 1.15vw; /* column-gap, row-gap */
    box-sizing: border-box;
}