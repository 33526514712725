.tech-article-detail-box {
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    justify-content: center;
    padding-left: 14vw;
    padding-right: 9.4vw;
}

.tech-article-detail-content-box {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: 6.5vh;
    padding-left: 1.75vw;
    padding-right: 3.1vw;
    padding-bottom: 17.7vh;
}

.tech-article-detail-related-box {
    width: 20%;
    margin-left: 2.7vw;
    display: flex;
    flex-direction: column;
}

.tech-article-detail-title {
    font-size: 3rem;
    font-weight: 600;
    color: #E4E8F2;
}

.tech-article-detail-meta-box {
    display: flex;
    flex-direction: row;
    margin-top: 1.85vh;
    border-bottom: 2px solid #2F3138;
    padding-bottom: 2.2vh;
}

.tech-article-detail-logo-box {
    width: 3rem;
    height: 3rem;
}

.tech-article-detail-logo-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.tech-article-detail-author-and-date-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-left: 0.8vw;
    color: #949AA9;
}

.tech-article-detail-company-name {
    color: #D2D7E4;
    font-size: 1rem;
    font-weight: 600;
}

.tech-article-detail-count-box {
    display: flex;
    justify-content: space-between;
    margin-top: 0.75vh;
}

.tech-article-detail-author-article-count {
    color: #A8AFC1;
    font-size: 0.8125rem;
    font-weight: 500;
}

.tech-article-detail-article-view-count {
    color: #949AA9;
    font-size: 1rem;
    font-weight: 500;
}

.tech-article-detail-category-box {
    display: flex;
    flex-direction: row;
    column-gap: 0.4vw;
    margin-top: 1.4vh;
    padding-bottom: 1.4vh;
    border-bottom: 2px solid #2F3138;
}

.tech-article-detail-category-item {
    display: flex;
    padding: 0.5rem 0.83rem;
    justify-content: center;
    align-items: center;

    background-color: #34363C;
    color: #FFC52E;
    border-radius: 9999px;
    cursor: pointer;
    box-sizing: border-box;
}

.tech-article-detail-content {
    display: flex;
    flex-direction: column;
    margin-top: 3.7vh;
}

.tech-article-detail-content > div > h3 {
    font-size: 1.5rem;
    color: #FFFFFF;
    font-weight: 500;
}

.tech-article-detail-pin-point-text-box {
    margin-bottom: 5.5vh;
}

.tech-article-detail-pin-point-text {
    margin-top: 1.48vh;
    font-size: 1.125rem;
    color: #91949D;
}

.tech-article-detail-core-text-box {
    margin-bottom: 4.44vh;
}

.tech-article-detail-core-summary-section {
    white-space: pre-line;
    margin-top: 1.48vh;
    font-weight: 400;
    font-size: 1.125rem;
    color: #AFB3BD;
}

.tech-article-detail-core-summary-title {
    color: #E0E3EB;
    margin-top: 1rem;
}

.tech-article-detail-core-summary-list {
    color: #91949D;
}

.tech-article-detail-core-summary-item {
    padding-top: 0.5rem;
}

.tech-article-detail-three-line-summary-text-box {
    margin-bottom: 5.5vh;
}

.tech-article-detail-three-line-summary-text {
    margin-top: 1.48vh;
    font-size: 1.125rem;
    color: #91949D;
}

.tech-article-detail-og-tag-box {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 18.5vh;
    background-color: #26272D;
    padding: 20px;
    margin-bottom: 7vh;
    text-decoration: none;
}

.tech-article-detail-og-tag-img {
    width: 13.3vw;
    height: 14.8vh;
    border-radius: 16px;
}

.tech-article-detail-og-tag-meta-box {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2vw;
}

.tech-article-detail-og-tag-title {
    font-size: 1.5rem;
    color: #E4E8F2;
    font-weight: 600;
}

.tech-article-detail-og-tag-url {
    margin-top: 0.7vh;
    color: #FFC52E;
    text-decoration: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tech-article-detail-related-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #C9CED9;
    margin-bottom: 2.22vh;
}

.tech-article-detail-related-content-box {
    display: flex;
    flex-direction: column;
    row-gap: 2.22vh;
}

.tech-article-detail-related-nav {
    text-decoration: none;
}

.tech-article-detail-related-content-img {
    width: 100%;
    height: 15.2vh;
    margin-bottom: 0.75vh;
    border-radius: 8px;
}

.tech-article-detail-related-content-title {
    color: #9DA3AD;
}
